import { getApp, getApps, initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, Messaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "tierhub-6de17.firebaseapp.com",
  projectId: "tierhub-6de17",
  storageBucket: "tierhub-6de17.firebasestorage.app",
  messagingSenderId: "290632300682",
  appId: "1:290632300682:web:bef99edb9cdb9cf14f9573",
  measurementId: "G-FBHJ9LPLBP",
};

const isBrowserSupported = () => {
  return "Notification" in window && "serviceWorker" in navigator && "indexedDB" in window;
};

let app = null;
export let messaging: Messaging | null = null;

if (isBrowserSupported()) {
  app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
  messaging = getMessaging(app);
} else {
  console.warn("This browser doesn't support the required APIs for Firebase.");
}

export const generateTokenFirebase = async () => {
  if (!messaging) {
    console.warn("Firebase messaging is not initialized due to unsupported browser.");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      console.log("token: ", token);
      return token;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const removeTokenFirebase = async () => {
  if (!messaging) {
    console.warn("Firebase messaging is not initialized due to unsupported browser.");
    return;
  }

  try {
    await deleteToken(messaging);
    console.log("Delete token successfully");
  } catch (error) {
    console.log("Delete token failed");
  }
};
